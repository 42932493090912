import $ from 'jquery';
import 'air-datepicker/dist/js/datepicker.js';
import 'air-datepicker/dist/js/i18n/datepicker.en.js';
import 'bootstrap-select';
import 'chosen-js';
import 'debounce';
import 'tablesorter';
import '../../../vendor/jquery.baraja/jquery.baraja.js';
import 'jquery-mask-plugin';
import 'jquery-ui/ui/widgets/draggable.js';
import 'jquery-ui.combobox/lib/jquery-ui.combobox.js';
import 'jquery-ui-multiselect-widget/src/jquery.multiselect.js';
import 'jquery-ui-multiselect-widget/src/jquery.multiselect.filter.js';
import 'jquery-validation';
require('../assessment/assessment-jquery-validation-unobtrusive.js'); // Must be loaded prior to jquery-validation-unobtrusive and via require
import 'jquery-validation-unobtrusive';
import 'toastr';
import 'qtip2';

import './custom-form-helpers.js';
import './date-picker.js';
import './masks.js';
import './mvc-requiredbase.js';
import './mvc-requiredcheckboxgroup.js';
import './mvc-requiredeitheror.js';
import './mvc-requiredeitherorif.js';
import './mvc-requiredif.js';

window.debounce = require('debounce');
window.toastr = require('toastr');