import $ from 'jquery';

// Enable hidden field validation for custom drop downs to work
$.validator.setDefaults({ ignore: null });

// Trigger validation events as needed for custom drop downs etc to trigger styling
$(document).ready(function () {
	let validator = $('form').data('validator');
	if (validator && validator.form) {
		let oldForm = validator.form;

		validator.form = function () {
			let result = oldForm.apply(this, arguments);
			$(this.currentForm).trigger((result == true) ? 'formValidationSuccess' : 'formValidationError', this.currentForm);
			return result;
		};
	}
});

// Password Show/Hide
var passwordToggle = function (instanceId) {
	var input = $('#' + instanceId + '-password input');
	var hidden = input.attr('type') == 'password';

	var img = $('#' + instanceId + '-password img');

	if (hidden) {
		input.attr('type', 'text');
		img.attr('src', '/Content/Images/hide-password@4x.png');
	}
	else {
		input.attr('type', 'password');
		img.attr('src', '/Content/Images/show-password@4x.png');
	}
};

// Drop Down
var dropDownToggle = function (instanceId) {
	var input = $('#' + instanceId + '-dropdown');
	var options = $('#' + instanceId + '-dropdown .dropdown-options');
	var expanded = !options.is(':hidden');

	var icon = $('#' + instanceId + '-dropdown .dropdown-icon');

	if (!expanded) {
		options.show();
		icon.removeClass('dropdown-icon-down');
		icon.addClass('dropdown-icon-up');
	}
	else {
		options.hide();
		icon.removeClass('dropdown-icon-up');
		icon.addClass('dropdown-icon-down');
	}
};

var dropDownSelect = function (instanceId, elem) {
	var selectedOptions = $('#' + instanceId + '-dropdown .dropdown-option-selected');
	selectedOptions.removeClass('dropdown-option-selected');

	var selectedValue = $(elem).data('value');
	var selectedText = $(elem).data('text');

	var hidden = $('#' + instanceId + '-dropdown input[type=hidden]');
	hidden.val(selectedValue).trigger('change');

	var defaultDisplay = $('#' + instanceId + '-dropdown .dropdown-option-default');
	var display = $('#' + instanceId + '-dropdown .dropdown-option-display');

	if (selectedValue != null
		&& selectedValue !== '') {
		defaultDisplay.hide();

		display.text(selectedText);
		display.show();
	}
	else {
		display.text('');
		display.hide();

		defaultDisplay.show();
	}

	$(elem).addClass('dropdown-option-selected');

	if ($(elem).is(':visible'))
		dropDownToggle(instanceId);
	dropDownValidate(instanceId);
};

var checkboxDropDownSelect = function (instanceId, elem) {
	/*
	var selectedOptions = $('#' + instanceId + '-dropdown .dropdown-option-selected');
	selectedOptions.removeClass('dropdown-option-selected');
	*/

	var hidden = $(elem).find('input[type="hidden"]');

	var selected = $(elem).hasClass('dropdown-option-selected');
	if (selected) {
		hidden.val('');

		$(elem).removeClass('dropdown-option-selected');
		$(elem).find('.fa').removeClass('fa-check-circle-o').addClass('fa-circle-o');
	}
	else {
		hidden.val(true);

		$(elem).addClass('dropdown-option-selected');
		$(elem).find('.fa').removeClass('fa-circle-o').addClass('fa-check-circle-o');
	}

	checkboxDropDownUpdate(instanceId);
};

var checkboxDropDownUpdate = function (instanceId) {
	var defaultDisplay = $('#' + instanceId + '-dropdown .dropdown-option-default');
	var display = $('#' + instanceId + '-dropdown .dropdown-option-display');

	var selected = $('#' + instanceId + '-dropdown .dropdown-option-selected');
	if (selected.length > 0) {
		var selectedText = '';
		selected.each((index, elem) => {
			if (selectedText !== '') selectedText += ', ';
			selectedText += $(elem).data('text');
		});

		defaultDisplay.hide();

		display.text(selectedText);
		display.show();
	}
	else {
		display.text('');
		display.hide();

		defaultDisplay.show();
	}
};

var checkboxDropDownCollapse = function (instanceId) {
	defaultDisplay.hide();

	display.text('foo');
	display.show();

	dropDownToggle(instanceId);
	dropDownValidate(instanceId);
};

var dropDownValidate = function (instanceId) {
	if ($('#' + instanceId + '-dropdown input[type=hidden].input-validation-error').length > 0) {
		$('#' + instanceId + '-dropdown .input-dropdown').addClass('input-validation-error');
	}
	else {
		$('#' + instanceId + '-dropdown .input-dropdown').removeClass('input-validation-error');
	}
};

// Exports
window.passwordToggle = passwordToggle;
window.dropDownToggle = dropDownToggle;
window.dropDownSelect = dropDownSelect;
window.checkboxDropDownSelect = checkboxDropDownSelect;
window.checkboxDropDownUpdate = checkboxDropDownUpdate;
window.dropDownValidate = dropDownValidate;
