import $ from 'jquery';
import 'air-datepicker/dist/js/datepicker.js';
import 'air-datepicker/dist/js/i18n/datepicker.en.js';

$(document).ready(function () {
	var $controls = $('.datepicker-control');

	var opts = {
		language: 'en',
		dateFormat: 'mm/dd/yyyy'
	};

	$controls.each((index, elem) => {
		var $elem = $(elem);
		$elem.attr('autocomplete', 'off');

		if ($elem.val()) {
			$elem.val($elem.val().replace(/\s+.*$/, ''));

			if ($elem.val().length === 9) {
				$elem.val('0' + $elem.val());
			}

			var $picker = $elem.datepicker(opts).data('datepicker');
			if (new Date($elem.val()) != "Invalid Date") {
				$picker.selectDate(new Date($elem.val()));
			}
		}
		else {
			$elem.datepicker(opts);
		}
	});
});