import $ from 'jquery';
import 'jquery-validation';
require('../assessment/assessment-jquery-validation-unobtrusive.js'); // Must be loaded prior to jquery-validation-unobtrusive and via require
import 'jquery-validation-unobtrusive';

$.validator.unobtrusive.adapters.add('requiredcheckboxgroup', [], function (options) {
	options.rules['requiredcheckboxgroup'] = options.params;
	options.messages['requiredcheckboxgroup'] = options.message;
});

$.validator.addMethod('requiredcheckboxgroup', function (value, element, parameters) {
	return $('input[name="' + parameters + '"]:checked').length > 0;
});	